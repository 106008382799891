<template>
  <div class="section-phone w-100 pb-3 pb-md-5 pb-xxl-5">
    <b-container class="pt-0 pt-xxl-5 container-mlg pl-xxl-0 pr-xxl-0">
      <b-row>
        <b-col lg="3">
          <div class="d-flex align-items-center">
            <div class="phone"><esports-phone /></div>
            <div class="d-block d-lg-none pl-3">
              <TextContent  />
            </div>
          </div>
        </b-col>
        <b-col lg="9" align-self="end">
          <div class="content">
            <div class="d-none d-lg-block">
              <TextContent />
            </div>
            <b-card-group class="p-0">
              <b-card class="border-0" no-body>
                <b-card-header>
                  <esports-icon-cloud />
                  <span class="pl-4">ダウンロードはこちら</span>
                </b-card-header>
                <div
                  class="
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                    app-content
                    pl-3
                    pr-4
                    pt-md-3

                  "
                >
                  <div class="d-flex align-items-center py-md-2">
                    <b-link href="https://itunes.apple.com/jp/app/id1611529736?mt=8" target="_blank" class="pt-md-2 mr-md-2"><esports-app-store class="app-store" /></b-link>
                    <qrcode-vue class="my-2 my-md-0" margin="15" value="https://itunes.apple.com/jp/app/id1611529736?mt=8" level="H"></qrcode-vue>
                  </div>
                  <div class="d-flex align-items-center py-md-2">
                    <b-link href="https://play.google.com/store/apps/details?id=jp.co.digilo.donate_esport&hl=ja&gl=jp" target="_blank" class="pt-md-2 mr-md-2"><esports-google-play class="google-play" /></b-link>
                    <qrcode-vue class="my-2 my-md-0" margin="15" value="https://play.google.com/store/apps/details?id=jp.co.digilo.donate_esport&hl=ja&gl=jp" level="H"></qrcode-vue>
                  </div>
                </div>
              </b-card>
            </b-card-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import TextContent from './Content/TextContent'
export default {
  name: "SectionPhone",
  components: { TextContent, QrcodeVue }
};
</script>