<template>
  <div class="txt-content">
    <h4 class="pb-0 mb-md-3">アプリの使い方</h4>
    <h5 class="pb-2 pb-md-5 mb-md-3">
      アプリの利用料は無料ですが、ダウンロードやご利用時にかかるパケット通信料はお客さまのご負担となります。※アプリ<br />
      のダウンロードには通信費がかかる場合があります。※Appleロゴは米国その他の国で登録されたApple
      Inc.の商標で<br />
      す。App StoreはApple Inc.のサービスマークです。※Google Play および Google
      Play ロゴは Google LLC の商標です。
    </h5>
  </div>
</template>

<script>
export default {
    name: "TextContent"
};
</script>